import InfluencerCarousal from '@/components/InfluencerCarousal/index.vue'
import CourseCarousal from '@/components/CourseCarousal/index.vue'
import Chat from '@/views/Apps/Chat/Index'
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  name: 'InfluencerProfile',
  components: { InfluencerCarousal, CourseCarousal, Chat },
  data () {
    return {
      bio: '',
      contentLoaded: false,
      influencerName: '',
      influencerFirstName: '',
      feedLoaded: 0,
      feedData: [],
      showFeeds: false,
      influencerDesignation: '',
      influencerSkills: '',
      profile_image: '',
      likes: '',
      followers: '',
      heartColor: 'https://static.overlay-tech.com/assets/d32338c0-f6d2-4b52-b8e9-fac094df88d2.svg',
      toggle: false,
      toogleFollowers: false,
      followIcon: '',
      heartRed: 'https://static.overlay-tech.com/assets/0615f301-23bf-45b4-8893-5d3edc5dcbea.svg',
      currentActiveIndex: 0,
      row1: {
        mainHeading: 'ZOOMIFEED',
        feedHeading: 'Leadership'
      },
      row2: {
        mainHeading: 'DATA DRIVEN',
        feedHeading: 'Sports Management'
      },
      row3: {
        mainHeading: 'DATA DRIVEN',
        feedHeading: 'Scott’s Tips For Balanced Living'
      },
      row10: {
        mainHeading: 'SOCIAL ENGINE',
        feedHeading: 'Business'
      },
      row4: {
        mainHeading: 'DATA DRIVEN',
        feedHeading: 'Other Interesting Playlist'
      },
      row6: {
        mainHeading: 'SOCIAL ENGINE',
        feedHeading: 'People you should checkout'
      },
      row7: {
        mainHeading: 'DATA DRIVEN',
        feedHeading: 'Somebody cools playlist'
      },
      cardColorBlack: {
        'bgColor': 'black',
        'fontColor': 'white',
        'mainColor': 'black',
        'feedColor': 'black'
      },
      cardColorWhite: {
        'bgColor': 'white',
        'fontColor': 'black',
        'mainColor': 'red',
        'feedColor': 'white'
      }
    }
  },
  computed: {
    ...mapGetters([
      'getAnalyticsState',
      'getInfluencer',
      'getInfluencersProfileCategories',
      'getInfluencersProfilePlaylist',
      'getInfluencerInteraction'
    ])
  },
  methods: {
    ...mapActions([
      'RESET_STATE',
      'GET_CONTACTS',
      'CLEAR_CATEGORIES_FEED',
      'GET_INFLUENCER',
      'GET_PROFILE_FEED_PLAYLIST',
      'GET_PROFILE_FEED_CATEGORIES',
      'PERFORM_INTERACTIONS',
      'GET_INTERACTIONS'
    ]),
    ...mapMutations([
      'setLoggedUser',
      'setPage',
      'setType',
      'setAction',
      'setName',
      'setLike',
      'setUserId',
      'setFollow'
    ]),
    bombIsAway (eventType, action) {
      console.log(eventType + ' ' + action)
      this.setType('USER')
      this.setAction(action)
      this.setName(this.getInfluencer.name)
      this.setLike(this.getInfluencerInteraction.like)
      this.setFollow(this.getInfluencerInteraction.follow)
      this.setUserId(this.$route.params.id)

      console.log(this.getAnalyticsState)
      // eslint-disable-next-line no-undef
      divolte.signal(eventType, this.getAnalyticsState)
    },
    changeHeart () {
      this.GET_INTERACTIONS({
        params: {
          'followingId': this.$route.params.id
        }
      })
      if (this.toggle) {
        this.heartColor = 'https://static.overlay-tech.com/assets/d32338c0-f6d2-4b52-b8e9-fac094df88d2.svg'
        this.PERFORM_INTERACTIONS({
          params: {
            'followingId': this.$route.params.id,
            'like': false,
            'follow': this.toogleFollowers
          }
        })
        this.bombIsAway('userCardClick', 'unlike')
        this.likes--
      } else {
        this.heartColor = 'https://static.overlay-tech.com/assets/0615f301-23bf-45b4-8893-5d3edc5dcbea.svg'
        this.PERFORM_INTERACTIONS({
          params: {
            'followingId': this.$route.params.id,
            'like': true,
            'follow': this.toogleFollowers
          }
        })
        this.setLike(true)
        this.bombIsAway('userCardClick', 'like')
        this.likes++
      }
      this.toggle = !this.toggle
    },
    changeFollow () {
      if (!this.toogleFollowers) {
        this.followIcon = 'https://static.overlay-tech.com/assets/5f7007e5-27e0-454b-b0c6-19eb5716c983.png'
        this.setFollow(true)
        this.bombIsAway('userCardClick', 'follow')
        this.followers++
      } else {
        this.followIcon = 'https://static.overlay-tech.com/assets/2ff01391-955c-4041-acfb-78ef0660a145.png'
        this.setFollow(false)
        this.bombIsAway('userCardClick', 'unfollow')
        this.followers--
      }
      this.PERFORM_INTERACTIONS({
        params: {
          'followingId': this.$route.params.id,
          'like': this.toggle,
          'follow': !this.toogleFollowers
        }
      })
      this.toogleFollowers = !this.toogleFollowers
    }
  },
  mounted () {
    console.log('On INFLUENCER_PROFILE')
    this.setPage('INFLUENCER_PROFILE')
    // eslint-disable-next-line no-undef
    divolte.signal('pageView', this.getAnalyticsState)
    this.GET_INTERACTIONS({
      params: {
        'followingId': this.$route.params.id
      },
      success: () => {
        this.toggle = this.getInfluencerInteraction.like
        this.toogleFollowers = this.getInfluencerInteraction.follow
        if (this.toggle) {
          this.heartColor = 'https://static.overlay-tech.com/assets/0615f301-23bf-45b4-8893-5d3edc5dcbea.svg'
        } else {
          this.heartColor = 'https://static.overlay-tech.com/assets/d32338c0-f6d2-4b52-b8e9-fac094df88d2.svg'
        }
        if (this.toogleFollowers) {
          this.followIcon = 'https://static.overlay-tech.com/assets/5f7007e5-27e0-454b-b0c6-19eb5716c983.png'
        } else {
          this.followIcon = 'https://static.overlay-tech.com/assets/2ff01391-955c-4041-acfb-78ef0660a145.png'
        }
      }
    })
  },
  created () {
    this.$store.dispatch('CLEAR_CATEGORIES_FEED', [])
    this.feedLoaded = 0
    let userId = this.$route.params.id
    this.contentLoaded = false
    this.GET_INFLUENCER({
      params: {
        'userId': userId
      },
      success: () => {
        this.contentLoaded = true
        this.influencerName = this.getInfluencer.name
        this.profile_image = this.getInfluencer.profileUrl
        this.influencerDesignation = this.getInfluencer.jobTitle
        this.bio = this.getInfluencer.description
        this.likes = this.getInfluencer.likes
        this.followers = this.getInfluencer.followers
        this.influencerFirstName = this.influencerName.split(' ')[0]
      }
    })
    this.GET_PROFILE_FEED_CATEGORIES({
      influencerId: this.$route.params.id,
      success: () => {
        this.feedLoaded = this.feedLoaded + 1
      }
    })
    this.GET_PROFILE_FEED_PLAYLIST({
      influencerId: this.$route.params.id,
      success: () => {
        this.feedLoaded = this.feedLoaded + 1
      }
    })
  },
  watch: {
    feedLoaded () {
      if (this.feedLoaded === 2) {
        if (this.getInfluencersProfileCategories > this.getInfluencersProfilePlaylist) {
          this.feedData = this.getInfluencersProfileCategories
        } else {
          this.feedData = this.getInfluencersProfilePlaylist
        }
        this.showFeeds = true
      }
    }
  }

}
